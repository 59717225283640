import React from 'react';
import { Analytics } from '@vercel/analytics/react';


const App = () => {
  return (
    <div className="min-h-screen bg-white font-['Inter']">
      {/* Main Container - increased max-width */}
      <div className="max-w-4xl mx-auto px-6 py-16 sm:px-8 md:px-12">
        {/* Hero Section */}
        <div className="mb-16">
          <h1 className="text-3xl sm:text-4xl font-light tracking-tight text-gray-900 mb-8">
            About Cheng Hua
          </h1>
          <p className="text-base sm:text-lg text-gray-600 leading-relaxed max-w-3xl mb-4">
            Currently working on <a href="https://www.youtube.com/watch?v=3X850-6YvAI" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">proactive AI Agents</a> on Smart glasses for ER Doctors @<a href="https://www.media.mit.edu/" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">MIT Media Lab</a>.
          </p>
          <p className="text-base sm:text-lg text-gray-600 leading-relaxed max-w-3xl">
            As computing power grows exponentially, human-computer interaction remains limited by our biological input/output mechanism. Building towards a harmonious future where technology becomes a true bicycle for the mind.
          </p>
        </div>
        <Analytics />


        {/* Experience Section with tighter spacing */}
        <div className="space-y-6">
          <h2 className="text-xl font-light text-gray-900 pb-2 border-b border-gray-200">
            Previous Experiences
          </h2>
          
          <div className="space-y-3">
            {/* Binge.AI */}
            <div className="group hover:bg-gray-50 -mx-4 p-6 rounded-lg transition-all duration-300">
              <div className="space-y-2.5">
                <div className="flex items-baseline justify-between">
                  <h3 className="text-lg font-medium text-gray-900">
                    <a href="https://bingeai.net" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">Binge.AI</a>
                  </h3>
                  <span className="text-gray-500 text-sm font-light">
                    ($3M valuation)
                  </span>
                </div>
                <p className="text-base text-gray-600 leading-relaxed">
                  Co-founded this company and raised $300k at age 16. An AI companion that watches YouTube with users in real-time, imagine watching the presidential debate with Elon Musk and Karl Marx.
                </p>
              </div>
            </div>

            {/* FLOW */}
            {/* <div className="group hover:bg-gray-50 -mx-4 p-4 rounded-lg transition-all duration-300">
              <div className="space-y-1.5">
                <h3 className="text-base font-medium text-gray-900">
                  FLOW
                </h3>
                <p className="text-sm text-gray-600 leading-relaxed">
                  Developed VR meditation platform reaching 15,000+ students across nation, making mindfulness accessible through technology.
                </p>
              </div>
            </div> */}

            {/* Digital Archive */}
            {/* <div className="group hover:bg-gray-50 -mx-4 p-4 rounded-lg transition-all duration-300">
              <div className="space-y-1.5">
                <h3 className="text-base font-medium text-gray-900">
                  Digital Archive for Written News
                </h3>
                <p className="text-sm text-gray-600 leading-relaxed">
                  Built comprehensive digital preservation system processing 500GB+ of historical newspapers dating to 1700s, expanding to multiple schools and libraries nationwide.
                </p>
              </div>
            </div> */}
          </div>
        </div>

        {/* Footer */}
        <footer className="mt-16 text-center">
          <a 
            href="https://twitter.com/FisherTherapist" 
            className="text-gray-500 hover:text-gray-900 transition-colors duration-300 inline-flex items-center space-x-2 text-sm"
          >
            <span>Follow on Twitter</span>
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
            </svg>
          </a>
        </footer>
      </div>
    </div>
  );
};

export default App;